import { aciosInstance } from '../../network/network';

export const OrderService = {
    mailOrder: async (payload) => {
        const body = {...payload};
        const headers = {'Content-Type': 'application/json'};
        return await aciosInstance.post('/orderMail', body, headers);
    },
    
    saveOrder: async (payload) => {
        const body = {...payload};
        const headers = {'Content-Type': 'application/json'};
        return await aciosInstance.post('/saveOrder', body, headers);
    }
};