import React  from "react";
import './styles.scss';

export const Loader = (props) => {
    return (
        <section className="spinner-holder">
            <section className='spinner'>
                <h4 
                    data-text='F'
                    className='spinner-title'>
                        F
                </h4>
            </section>
        </section>
    )
}