import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Menu } from '../../Components/Menu/Menu';
import { Footer } from '../../Components/Footer/Footer';
import { Posts } from '../Posts/Posts';
import { Products } from '../Products/Products';
import { Categories } from '../Categories/Categories';

import { ProductDetails } from '../ProductDetails/ProductDetails';
import PageShell from '../PageShell/PageShell';
import { Cart } from '../Cart/Cart';
import { Order } from '../Order/Order';
import { ConfirmScreen } from '../ConfirmScreen/ConfirmScreen';
import { Home } from '../Home/Home';

const App = (props) => 
<Router>
    <Menu />
      <Switch location={props.location}>
        <Route exact path="/">
          {PageShell(Home)}
        </Route>
        <Route path="/blog">
          {PageShell(Posts)}
        </Route>
        <Route path="/categories">
          {PageShell(Categories)}
        </Route>
        <Route path="/products">
          {PageShell(Products)}
        </Route>
        <Route path="/product-details">
          {PageShell(ProductDetails)}
        </Route>
        <Route path="/cart">
          {PageShell(Cart)}
        </Route>
        <Route path="/order">
          {PageShell(Order)}
        </Route>
        <Route path="/payment">
          {PageShell(Order)}
        </Route>
        <Route path="/finalize">
          {PageShell(ConfirmScreen)}
        </Route>
      </Switch>
      <Footer />
</Router>;
 
export default App;