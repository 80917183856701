import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

export const CartItem = (props) => {
    const { item, removeItemFromCart, onCountChange, isDissabled } = props;
    const [quantity, setQuantity] = useState(1);
    const onChange = (event) => {
        const { value } = event.target;
        item.quantity = value;
        setQuantity(value);
        onCountChange(item);
    };

    return (<div className='item'>
        <div className='item-image'>
            <img src={item.images && item.images[0] && item.images[0].default} alt='product_image'/>
        </div>
        <div className='item-name'>
            <span>{item.Name}</span>
            <span>{item.Working_Length}</span>
            <span>{item.Size}</span>
        </div>
        <div className='item-count'>
            <span>Брой:</span>
            <input 
                type='number'
                onChange={onChange}
                value={item.quantity || quantity}
                disabled={isDissabled}
                name={item.Id}
            />
            <span>x</span>
            <span>{Number.parseFloat(item.Price).toFixed(2)}&nbsp;лв</span>
        </div>
        {!isDissabled && <div className='item-actions' onClick={() => removeItemFromCart(item)}>
            <FontAwesomeIcon
                color='#E64E4E' 
                className='rate-star' 
                size='2x' 
                icon={faTrash} 
            />
        </div>}
    </div>);
}