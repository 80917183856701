import React, { useEffect, useState }  from "react";
import './styles.scss';
import { SubCategories } from "./Subcategory";
import ReactPixel from '../../Services/reactPixels';

export const Categories = props => {
    const [category, setCategory] = useState(null);

    useEffect(() => {
        ReactPixel.pageView();
    }, [])

    const select = (event) => {
            if (event.target.dataset.category !== '0')
                setCategory(event.target.dataset.category);
            return;
        }

    if (category) {
        return(
            <section className='categories'>
                <h2 className='categories-header'>Добре дошли в нашият електронен каталог</h2>
                <SubCategories category={category} select={select} />
            </section>)
    }

    return (<section className='categories'>
            <h2 className='categories-header'>Добре дошли в нашият електронен каталог</h2>
            <section className='categories-holder'>
                <div className="categories-item" onClick={select} data-category='1'>
                    <div className="front"/>
                    <div className="back"><span>Ендодонтски ръчни инструменти</span></div>
                </div>
                <div className="categories-item" onClick={select} data-category='2'>
                    <div className="front"/>
                    <div className="back"><span>Ендодонтски машинни инструменти</span></div>
                </div>
                <div className="categories-item" onClick={select} data-category='3'>
                    <div className="front"/>
                    <div className="back"><span>Инструменти за обстурации на зъбни канали </span></div>
                </div>
                <div className="categories-item" onClick={select} data-category='4'>
                    <div className="front"/>
                    <div className="back"><span>Борчета</span></div>
                </div>
                <div className="categories-item" onClick={select} data-category='5'>
                    <div className="front"/>
                    <div className="back"><span>Хирургични игли с конци</span></div>
                </div>
                <div className="categories-item" onClick={select} data-category='6'>
                    <div className="front"/>
                    <div className="back"><span>Други продукти</span></div>
                </div>
                <div className="categories-item" onClick={select} data-category='7'>
                    <div className="front"/>
                    <div className="back"><span>ROEKO продукти</span></div>
                </div>
                <div className="categories-item" onClick={select} data-category='8'>
                    <div className="front"/>
                    <div className="back"><span>Jizai</span></div>
                </div>
                <div className="categories-item" onClick={select} data-category='9'>
                    <div className="front"/>
                    <div className="back"><span>Korean Materials</span></div>
                </div>
            </section>
        </section>);

}
