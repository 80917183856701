import * as PostsActions from './postsActions';

let initialPostsState = {
    posts: [],
    totalCount: 0,
    loading: false,
    error: null
}

export const postsReducer = (state=initialPostsState, action) => {
    switch (action.type) {
        // eslint-disable-next-line no-lone-blocks
        case PostsActions.GET_POSTS_INIT: {
            return { 
                ...state,
                posts: [],
                loading: true,
                error: null
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case PostsActions.GET_POSTS_SUCCESS: {
            return { 
                ...state,
                loading: false,
                posts: [...action.result.data.results],
                totalCount: action.result.totalCount
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case PostsActions.GET_POSTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error.response.data
            };
        };
        default:
            return state;
    }
}