import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './styles.scss';

const PageShell = Page => {
  return props =>
    {
    return <div className="page">
      <ReactCSSTransitionGroup
        key={props.location.key}
        transitionAppear={true}
        transitionAppearTimeout={600}
        transitionEnterTimeout={600}
        transitionLeaveTimeout={200}
        transitionName={props.match.path === props.location.pathname ? 'SlideIn' : 'SlideOut'}
      >
        <Page {...props} />
      </ReactCSSTransitionGroup>
    </div>};
};

export default PageShell;
