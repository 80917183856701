import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/trlogo.png";
import "./styles.scss";

export const Footer = (props) => {
  return (
      <section className="footer-holder">
        <ul className="footer-section">
          <li className="footer-section-item">
            <img src={logo} alt="logo" />
          </li>
          <li className="footer-section-item">
            <span>Фейс ООД</span>
          </li>
          <li className="footer-section-item-disabled">
            <a target="_blank" rel="noreferrer" href="http://www.mani.co.jp/"><i>MANI</i></a>  
          </li> 
          <li className="footer-section-item">
            <span>All rights reserved</span>
          </li>
        </ul>
        <ul className="footer-section">
          <li className="footer-section-item">
            <span>гр. София ул. „проф. Фритьоф Нансен“ 21</span>  
          </li> 
          <li className="footer-section-item">
            <span>Моб: 0885643491</span>
          </li>
          <li className="footer-section-item">
            <span>Моб: 0885643492</span>
          </li>
          <li className="footer-section-item">
            <span>email:face_bg&#64;yahoo.com</span>
          </li>
        </ul>
        <ul className="footer-section">
          <li className="footer-section-item">
            <Link to="/">За&nbsp;Фейс</Link>  
          </li> 
          <li className="footer-section-item">
            <Link to="/blog">Блог</Link>
          </li>
          <li className="footer-section-item">
            <Link to="/products">Продукти</Link>
          </li>
          <li className="footer-section-item">
            <Link to="/cart">Кошница</Link>
          </li>
        </ul>
      </section>
  );
};
