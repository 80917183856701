import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as ProductActions from './productActions';
import { ProductsService } from './services';
import * as Selectors from '../../Selectors';

export function* watchProductsSaga () {
    yield takeLatest(ProductActions.GET_PRODUCTS_INIT, fetchProducts);
    yield takeLatest(ProductActions.LOAD_MORE_INIT, fetchMoreProducts);
    yield takeLatest(ProductActions.RATE_PRODUCT_INIT, rateProduct);
    yield takeLatest(ProductActions.FILTER_PRODUCTS, filterProducts);
}

export function* fetchProducts(action) {
    const { skip, take, category, refresh } = action.data;
    let result = null
    try {
      const products = yield select(Selectors.products);
      const productsData = yield select(Selectors.productsData);

      if((products && products.length === 0) || (skip !== productsData.filters && productsData.filters.skip) || refresh) {
         result = yield call(ProductsService.getProducts, category, skip, take);
      } else {
         result = {
            data: {
               results: products,
               totalCount: productsData.totalCount
            }
         } 
      }
      yield put(ProductActions.getProductsSuccess(result));
   } catch (error) {
      yield put(ProductActions.getProductsFail(error));
   }
}

export function* fetchMoreProducts(action) {
   const { skip, take, filterBy, category, refresh } = action.data;
   let result = null;
   try {
     const products = yield select(Selectors.products);
     const productsData = yield select(Selectors.productsData); 
    
     if ((products && products.length === 0) || (skip !== productsData.filters && productsData.filters.skip) || refresh) {
          result = yield call(ProductsService.filterProductsBy, filterBy, category, skip, take);
      } else {
         result = {
            data: {
               results: products,
               totalCount: productsData.totalCount
            }
         } 
      }
     yield put(ProductActions.loadMoreSuccess(result));
  } catch (error) {
     yield put(ProductActions.loadMoreFail(error));
  }
}

export function* filterProducts(action) {
   const { filterBy, category } = action.data;
   try {
     const result = yield call(ProductsService.filterProductsBy, filterBy, category);
     yield put(ProductActions.filterProductsSuccess(result));
  } catch (error) {
     yield put(ProductActions.filterProductsFail(error));
  }
}

export function* rateProduct(action) {
  const { rate, productId, category } = action.data;

  try {
    const result = yield call(ProductsService.rateProduct, rate, productId, category);
    yield put(ProductActions.rateProductSuccess(result));
 } catch (error) {
    yield put(ProductActions.rateProductFail(error));
 }
}