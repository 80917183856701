import { all } from 'redux-saga/effects';
import { watchPostsSaga } from '../Screens/Posts/sagas';
import { watchProductsSaga } from '../Screens/Products/saga';
import { watchOrderSaga } from '../Screens/Order/saga';
import { watchMailSaga } from '../Screens/Mail/saga';

export function* runAllSagas() {
    yield all([
        watchPostsSaga(),
        watchProductsSaga(),
        watchOrderSaga(),
        watchMailSaga()
    ]);
}