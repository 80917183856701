/* eslint-disable no-fallthrough */
import * as ProductsActions from './productActions';

const initialProducts = {
    loading: false,
    loadMore: false,
    updateLoading: false,
    products: [],
    filters: {},
    totalCount: 0,
    selectedProduct: null,
    error: null,
    updateProduct: null
}

export const productsReducer = (state=initialProducts, action) => {
    switch (action.type) {
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.GET_PRODUCTS_INIT: {
            return {
                ...state,
                products: [],
                loading: true,
                error: null
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.GET_PRODUCTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                products: action?.result?.data?.results,
                totalCount: action.result.data.totalCount,
                error: null
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.GET_PRODUCTS_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.error?.response?.data,
                products: state.products
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.LOAD_MORE_INIT: {
            return { 
                ...state,
                loadMore: true,
                error: null
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.LOAD_MORE_SUCCESS: {
            return  {
                ...state,
                loadMore: false,
                products: [...state.products, ...action.result?.data?.results],
                totalCount: action.result.data.totalCount,
                error: null
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.LOAD_MORE_FAIL: {
            return {
                ...state,
                loadMore: false,
                error: action.error?.response?.data,
                products: state.products
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.RATE_PRODUCT_INIT: {
            return {
                ...state,
                lupdatedProduct: null,
                updateLoading: true
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.RATE_PRODUCT_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
                updatedProduct: action?.result?.data
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.FILTER_PRODUCTS: {
            return {
                ...state,
                products: [],
                loading: true,
                error: null
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.FILTER_PRODUCTS_SUCCEESS: {
            return {
                ...state,
                products: action.result.data.results,
                totalCount: action?.result?.data?.totalCount,
                loading: false,
                error: null
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.FILTER_PRODUCTS_FAIL: {
            return {
                ...state,
                error: action.error?.response?.data,
                loading: false,
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.RATE_PRODUCT_FAIL: {
            return {
                ...state, 
                updateLoading: false,
                error: action.error?.response?.data
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.SELECT_PRODUCT: {
            const product = state.products.filter(item => item.Id === action.id);
            return {
                ...state, 
                selectedProduct: product && product[0]
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.DESELECT_PRODUCT: {
            return {
                ...state, 
                selectedProduct: null
            };
        };
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.SAVE_FILTERS: {
            return {
                ...state, 
                filters: {...action.filters}
            };
        };     
        // eslint-disable-next-line no-lone-blocks
        case ProductsActions.CLEAR_FILTERS: {
            return {
                ...state, 
                filters: {}
            };
        };
        default:
            return state;
    }
}