import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth, faToolbox, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Slider } from '../../Components/Slider/Slider';
import { Section } from '../../Components/Section/Section';
import { SectionColumn } from '../../Components/SectionColumn/SectionColumn';
import { MapWrapper } from '../../Components/MapComponent/Map';
import * as CardActions from '../Cart/cartActions';
import * as MailActions from '../../Screens/Mail/mailActions';
import { useDispatch } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { checkExpiration } from '../../utils/utils';
import { Form } from '../../Components/Form/Form';
import ReactPixel from '../../Services/reactPixels';
import image1 from '../../assets/images/IMG_4888.JPEG';
import image2 from '../../assets/images/PLUGGERS-Slider2.jpg';
import bgimgage from '../../assets/images/pexels-rovenimagescom-949587.jpg';
import './styles.scss';

const style = {
    backgroundImage: `url(${bgimgage})`
}

export const Home = (props) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        const savedProducts = JSON.parse(sessionStorage.getItem('savedProducts'));
        const cartExpiresAt = sessionStorage.getItem('cartExpiresAt');
        ReactPixel.pageView();

        if(checkExpiration(cartExpiresAt)) {
            sessionStorage.clear();
            dispatch(CardActions.clearProductsFromCart());
        }

        if (savedProducts && Object.keys(savedProducts).length > 0) {
            dispatch(CardActions.loadProductFromStorage(savedProducts))
        }
    }, [dispatch]);

    const submitMail = (mailData) => {
        dispatch(MailActions.sendMail(mailData, false));
    } 

    return (
        <div className='main'>
            <MetaTags>
                <title>Фейс Начало</title>
                <meta name="description" content="Фейс дистрибутор на МАНИ Япония за Българя. Продажба на зъболекарски материали. Продажба на канални инструменти, борчета, пили, ултразвукови разширители, 
                        RT – пили, карбидни борчета, NRT- пили, машинни и др." />
                <meta name="keywords" content="Фейс, МАНИ, Япония за България, канални инструменти, борчета, пили, ултразвукови разширители, 
                        RT – пили, карбидни борчета, NRT- пили, машинни пили" />
                <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                <meta property="og:title" content="Фейс Уебсайт" />
                <meta property="og:description" content="Фейс дистрибутор на МАНИ Япония за Българя. Продажба на зъболекарски материали. Продажба на канални инструменти, борчета, пили, ултразвукови разширители, 
                        RT – пили, карбидни борчета, NRT- пили, машинни и др." />
                <meta property="og:url" content="http://www.faceeu.bg" />
                <meta property="og:image" content="/src/assets/images/home.jpg" />
                <meta property="og:width" content="350" />
                <meta property="og:height" content="450" />
                <meta property="og:type" content="jpg" />                
                <meta property="og:alt" content="Face home page" />
            </MetaTags>
            <Slider />
            <Section>
                <SectionColumn>
                    <h3 className='column-title'>Ние сме Фейс</h3>
                    <p className='column-text'>
                        Фирма “ФЕЙС” ООД е официалният представител на фирма <a target="_blank" rel="noreferrer" href="http://www.mani.co.jp/"><i>"MANI"</i> Япония за България</a>. Пълната гама продукти ендо пили
                        хирургични игли, борчета, ще намерите във офиса ни, намиращ се на ул. Фритьоф Нансен 21.
                        Поръчки можете да правите и през нашия сайт.
                    </p>
                </SectionColumn>
                <SectionColumn>
                   <img src={image1}alt='section-image1'/>
                </SectionColumn> 
            </Section>
            <Section title="Историята на Фейс" isParallax style={style} textStyle >
                <SectionColumn>
                   <img src={image2} alt='section-image2'/>
                </SectionColumn>
                <SectionColumn>
                    <p className='column-text'>
                        Фирма “ФЕЙС” ООД е създадена през 1991 г. в гр. София с предмет на дейност 
                        “Търговия на едро с медицински изделия”. Основно предлага на пазара стоматологични канални инструменти, 
                        консумативи, хигиенни материали, хирургични инструменти. Фирмата притежава необходимия лиценз за търговия на 
                        едро с медицински изделия HD 601429120001. През дългите години на работа, фирмата извоюва стабилни позиции на българския пазар, 
                        където успя да наложи редица нови за страната продукти 29 години активна пазарна дейност са гаранция за качество, 
                        висок професионализъм и надеждно партньорство.
                    </p>
                </SectionColumn>
            </Section>
            <Section title="Нашите Услуги">
                <SectionColumn>
                  <FontAwesomeIcon color='#E64E4E' size='2x' icon={faTooth} />
                   <h4 className='header-title'>Предлагаме цялата гама на <i>MANI</i></h4>
                   <p className='column-text column-text--smaller column-text--centered'>Която се състой от: Ендодонтски инструменти, Борчета, Хирургични игли</p>
                </SectionColumn>
                <SectionColumn>
                  <FontAwesomeIcon color='#E64E4E' size='2x' icon={faToolbox} />
                   <h4 className='header-title'>Kонсумативи и Дентални Инстументи</h4>
                   <p className='column-text column-text--smaller column-text--centered'>Консумативи Роеко, Матрици Тор, Клещи, Скалпели</p>
                </SectionColumn>
                <SectionColumn>
                  <FontAwesomeIcon color='#E64E4E' size='2x' icon={faTruck} />
                   <h4 className='header-title'>Доставка от врата до врата</h4>
                   <p className='column-text column-text--smaller column-text--centered'>Работим с Еконт и Спиди. При сметка над 100 лв доставката е за наша сметка.</p>
                </SectionColumn>
            </Section>
            <Section>
                <h2 className='header-title'>Заповяйдайте в нашия магазин на ул. Фритьоф Нансен 21</h2>
            </Section>
            <Section title='Наши партньори' isParallax style={style} textStyle>
                <span className='products_supliers'>MANI</span>
                <span className='products_supliers'>ROEKO</span>
                <span className='products_supliers'>TOR</span>
                <span className='products_supliers'>METABOMED</span>
            </Section>
            <Section>
                <MapWrapper />
            </Section>
            <Section>
                <SectionColumn>
                    <h4 className='column-title'>Свържете се с нас</h4>
                    <ul id='contacts'>
                        <li className='column-text'>
                            + 359889812027
                        </li>
                        <li className='column-text'>
                            + 359885643491
                        </li>
                        <li className='column-text'>
                            + 359885643492
                        </li>
                        <li className='column-text'>
                            face_bg&#64;yahoo.com
                        </li>
                    </ul>
                </SectionColumn>
                <SectionColumn>
                    <Form sendMail={submitMail} />
                </SectionColumn>
        </Section>
    </div>
    )
}