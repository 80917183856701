import image1 from '../images/frank-mckenna-OD9EOzfSOh0-unsplash.jpg';
import image2 from '../images/japan-in-pictures-most-beautiful-places-kinkaku-ji-golden-pavilion-kyoto.jpg';
import image3 from '../images/LicXsR.jpg';
import image4 from '../images/japan-castle2.jpg';

const slides = [
    {
        image: image1,
        title: 'Добре дошли във Фейс ООД България'
    },
    {
        image: image2,
        title: 'Официалeн представител на МАНИ Япония за България'
    },
    {
        image: image3,
        title: 'Оригинални продукти от МАНИ Япония'
    },
    {
        image: image4,
        title: 'Гарантирано Качество'
    },  
];

export default slides;