import { aciosInstance } from '../../network/network';

export const MailService = {
    sendOrderMail: async (data) => {
        return await aciosInstance.get(`/mailOrder`, data);
    },

    sendMail: async (data) => {
        return await aciosInstance.post(`/sendMail`, data);
    },
};