import { postsReducer } from '../Screens/Posts/postsReducer';
import { productsReducer } from '../Screens/Products/productReducer';
import { cartReducer } from '../Screens/Cart/cartReducer';
import { orderReducer } from '../Screens/Order/orderReducer';
import { mailReducer } from '../Screens/Mail/mailReducer';


import { combineReducers } from 'redux';

const reducers = combineReducers({
  postsData: postsReducer,
  productsData: productsReducer,
  cartData: cartReducer,
  orderData: orderReducer,
  mailData: mailReducer
});

export default reducers