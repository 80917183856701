import { call, put, takeLatest } from 'redux-saga/effects';
import * as OrderActions from '../Order/orderActions';
import { OrderService } from './services';

export function* watchOrderSaga () {
    yield takeLatest(OrderActions.SAVE_ORDER, saveOrderSaga);
}

export function* saveOrderSaga(action) {
    try {
      const data = action.data;
      yield call(OrderService.saveOrder, data);
      yield call(OrderService.mailOrder, data);
      yield put(OrderActions.saveOrderSuccess('success'));
    } catch (error) {
      yield put(OrderActions.saveOrderFail(error));
      console.log(error)
   }
}