import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as CartActions from '../Cart/cartActions';
import * as ProductActions from '../Products/productActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactImageMagnify from 'react-image-magnify';
import { faCartPlus, faAngleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ProductRate } from '../../Components/ProductRate/ProductRate';
import noimage from '../../assets/images/defaultproductimg.png';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import ReactPixel from '../../Services/reactPixels';
import { $ } from 'react-jquery-plugin'
import { useSelector, useDispatch } from 'react-redux';
import { rateProduct } from "../Products/productActions";
import './styles.scss';

export const ProductDetails = (props) => {
    const [rate, setRate] = useState(0);
    const [quantity, setQuantity] = useState(1)
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedProduct } = useSelector(state => state.productsData);

    useEffect(() => {
        if (!selectedProduct) {
           return history.goBack()
        } else if (selectedProduct && selectedProduct.rate) {
            setRate(selectedProduct.Rate);
        }
        ReactPixel.pageView();

        return () => {
            dispatch(ProductActions.deselectProduct());
        }
    }, [selectedProduct, dispatch, history]);

    const rateItem = (rate) => {
        setRate(rate)
        rateProduct(rate, selectedProduct.id, selectedProduct.Category);
    };

    const addProduct = (product) => dispatch(CartActions.addProductToCart(product));

    const addProductToCart = (event, product) => {
        addProduct(product)
        const parent = $(event.currentTarget.parentNode).parent()
        parent.find(".card-overlay").css({
            'transform': ' translateY(0px) translateZ(150px)',
            'opacity': '1',
            'transition': 'all ease-in-out .45s'
          }).delay(1500).queue(function() {
            $(this).css({
              'transform': 'translateY(-800px) translateZ(-150px)',
              'opacity': '0',
              'transition': 'all ease-in-out .45s'
            }).dequeue();
          });
    }

    const onChange = (event) => {
        const { value } = event.target;
        selectedProduct.quantity = Number(value);
        setQuantity(value);
    };

    if (!selectedProduct) {
        return (
        <div className="card-details">
            <h4>Нещо се обърка и неможахме да заредим желания</h4>    
        </div>);
    }


    return (
        <div className="card-details">
            <div className="back-button" onClick={() => history.goBack()}>
                <FontAwesomeIcon color='#313131' size='3x' icon={faAngleLeft} />
            </div>
            <div className="selectedProduct-info">
                <h3>{selectedProduct.Name}</h3>
                <div className="selectedProduct-info selectedProduct-info--row">
                    <h3>{selectedProduct.Size}</h3>
                    <h3>{selectedProduct.Working_Length}</h3>
                </div>
            </div>
            <div className="card-details-inner">
                <div className="card-details-slider">
                    {selectedProduct.In_Promo ? <div className="promo">
                        <span className="promo-content">Промоция</span>
                    </div> : null}
                    {selectedProduct.Is_New ? <div className="promo">
                        <span className="promo-content  promo-content--new">Ново</span>
                    </div> : null}
                    <AwesomeSlider>
                        {selectedProduct && selectedProduct.images.map((img, index) => <div key={selectedProduct.id+index}>
                            {img ? <ReactImageMagnify {...{
                                        smallImage: {
                                            alt: 'product',
                                            width: 320,
                                            height: 360,
                                            isFluidWidth: true,
                                            src: img?.default
                                        },
                                        largeImage: {
                                            alt: 'product enlarged',
                                            src: img?.default,
                                            width: 1500,
                                            height: 1600
                                        },
                                        shouldUsePositiveSpaceLens: true,
                                        enlargedImagePosition: 'over'
                                    }} />: null}
                                {!selectedProduct.images && <img src={noimage} alt="product"/>}
                            </div>)}
                    </AwesomeSlider>
                </div>
                <div className="card-details-text">
                        <div className="details-short">
                            <div className="details-short-inner">
                                <h4>Параметри</h4>
                                <span>{selectedProduct.Params || '--'}</span>
                            </div>
                            <div className="details-short-inner">
                                <h4>Описание</h4>
                                <span>{selectedProduct.Description || '--'}</span>
                            </div>
                            <div className="details-short-inner">
                                <h4>Свързани Продукти</h4>
                                <span>{selectedProduct.Related_Products || '--'}</span>
                            </div>
                        </div>
                        <div className="details-short">
                            <div className="details-short-inner details-short-inner--row">
                                <ProductRate rate={rate} rateItem={rateItem} />
                                <h4>Цена:</h4>
                                <span>{Number.parseFloat(selectedProduct.Price).toFixed(2)} лв.</span>
                            </div>    
                            <div className="details-short-inner details-short-inner--row">
                                <h4>Цвят</h4>
                                <div className="details-short-color" style={{backgroundColor: selectedProduct.Catalog_Color}}></div>
                            </div>
                        </div>
                        <div className="details-buttons-holder">
                            <div className="details-button-holder-inner">
                                <span>Брой:</span>
                                <input 
                                    type='number'
                                    onChange={onChange}
                                    value={quantity}
                                    name={selectedProduct.Id}
                                />
                            </div>
                            <div className="details-button-holder-inner" onClick={(event) => addProductToCart(event, selectedProduct)}>
                                <FontAwesomeIcon color='#EFE0E0' size='2x' icon={faCartPlus} />
                            </div>
                        </div>
                        <div className="card-overlay">
                            <h3>Добавено в кошницата</h3>
                            <FontAwesomeIcon color='#3C9015' size='3x' icon={faCheckCircle} />
                        </div>
                </div>
            </div>
        </div>
    );
}