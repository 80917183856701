import React, { useState, useEffect, useCallback } from "react";
import slides from '../../assets/data/slides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Slide } from './Slide';
import './slider.scss';

export const Slider = ({children}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const goToNext = useCallback(() => currentSlide >= slides.length - 1 ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1), [currentSlide]);
  const goToPrev = useCallback(() => currentSlide <= 0 ? setCurrentSlide(slides.length - 1) : setCurrentSlide(currentSlide - 1), [currentSlide]);
  
  // useEffect(() => {
  //   setTimeout(goToNext, 3500);
  // },[goToNext])

  if(children) {
    return (
      <section className='slider'>
        <div className='slider-controls' onClick={goToPrev}>
          <FontAwesomeIcon color={currentSlide === 0 ? '#47425D' : 'blanchedalmond'} size='2x' icon={faArrowAltCircleRight} />
        </div>
        {children}
        <div className='slider-controls' onClick={goToNext}>
          <FontAwesomeIcon color={currentSlide === 0 ? '#47425D' : 'blanchedalmond'} size='2x' icon={faArrowAltCircleLeft} />
        </div>
      </section>
    )
  }

  return (
    <section className='slider'>
      <div className='slider-controls' onClick={goToPrev}>
        <FontAwesomeIcon color={currentSlide === 0 ? '#47425D' : 'blanchedalmond'} size='2x' icon={faArrowAltCircleRight} />
      </div>
      <section className='slider-content'>
        {slides.map((slide, index) => <Slide
          key={index}
          isActive={index === currentSlide}
          index={index}
          title={slide.title}
          textStyle={index === 0 ? {color: '#47425D'}: {color: 'blanchedalmond'} }
          image={slide.image} />)}                                                                                                                                                                                                                                                                                      m  mmm kkkm 
      </section>
      <div className='slider-controls' onClick={goToNext}>
        <FontAwesomeIcon color={currentSlide === 0 ? '#47425D' : 'blanchedalmond'} size='2x' icon={faArrowAltCircleLeft} />
      </div>
    </section>
  )
}
