import * as MailActions from './mailActions';

const initialState = {
    loading: false,
    error: null,
    success: false,
}


export const mailReducer = (state=initialState, action) => {
    // eslint-disable-next-line default-case
    switch(action.type) {
        // eslint-disable-next-line no-lone-blocks
        case MailActions.SEND_MAIL_INIT: {
            return Object.assign({}, state, { 
                success: false,
                loading: true,
                error: null
            });
        };
        // eslint-disable-next-line no-lone-blocks
        case MailActions.SEND_MAIL_SUCCESS: {
            return Object.assign({}, state, { 
                success: true,
                loading: false,
                error: null
            });
        };
        // eslint-disable-next-line no-lone-blocks
        case MailActions.SEND_MAIL_FAIL: {
            return Object.assign({}, state, { 
                success: false,
                loading: false,
                error: action.error
            });
        };
        default:
            return state;
    }
}