import React from "react";
import "./slide.scss";

export const Slide = (props) => {
    const { image, title, isActive, index, textStyle } = props;
    const elem = document.getElementById(`slide${index}`);
    const size = elem && elem.clientWidth;

    
    return (
        <section id={`slide${index}`} className={`slide-container`}
        style={isActive ? {transform: `translate(${index * -size * 1}px)`} :  {transform: `translate(${index * size * 1}px)`}}>
            <section className='slide-image'>
                <img className='slide-img' src={image} alt={`slide ${image}`} />
                <h2 className='slide-title' style={textStyle}>{title}</h2>
            </section>
        </section>
    )
}

