import React from "react";
import { useHistory } from "react-router-dom";
import './styles.scss';

import bgImg1 from '../../assets/files/REAMERS_FILES_COVER.JPEG';
import bgImg2 from '../../assets/files/MEDIUM_REAMERS_COVER.JPEG';
import bgImg3 from '../../assets/files/K_FILES_COVER.JPEG';
import bgImg4 from '../../assets/files/MEDIUM_K_FILES_COVER.JPEG';
import bgImg5 from '../../assets/files/H_FILES_COVER.JPEG';
import bgImg6 from '../../assets/files/MEDIUM_H_FILES_COVER.JPEG';
import bgImg7 from '../../assets/files/NITI_H_FILES_COVER.JPEG';
import bgImg8 from '../../assets/files/FLARE_FILES_COVER.JPEG';
import bgImg9 from '../../assets/files/NITI_FLARE_FILES_COVER.JPEG';
import bgImg10 from '../../assets/files/SEC-O_K_FILES_COVER.JPEG';
import bgImg11 from '../../assets/files/SEC-O_H_FILES_COVER.JPEG';
import bgImg12 from '../../assets/files/FLEXILE_FILES_COVER.JPEG';
import bgImg13 from '../../assets/files/MEDIUM_FLEXILE_FILES_COVER.JPEG';
import bgImg14 from '../../assets/files/RT_FILES_COVER.JPEG';
import bgImg15 from '../../assets/files/D_FINDERS_COVER.JPEG';
import bgImg16 from '../../assets/files/BARBED_BROACHES_COVER.JPEG';
import bgImg17 from '../../assets/files/SQUARE_BARBED_BROACHES_COVER.JPEG';
import bgImg18 from '../../assets/files/SHORT_BARBED_BROACHES_COVER.JPEG';
import bgImg19 from '../../assets/files/ENGINE_REAMERS_COVER.JPEG';
import bgImg20 from '../../assets/files/SUPER_FILES_COVER.JPEG';
import bgImg21 from '../../assets/files/U_FILES_COVER.JPEG';
import bgImg22 from '../../assets/files/GPR_COVER.JPEG';
import bgImg23 from '../../assets/files/PEESO_REAMERS_COVER.JPEG';
import bgImg24 from '../../assets/files/GATE_DRILLS_COVER.JPEG';
import bgImg25 from '../../assets/files/PASTE_CARRIERS_COVER.JPEG';
import bgImg26 from '../../assets/files/SPREADERS_25MM_15-40.JPG';
import bgImg27 from '../../assets/files/FLARE_SPREADER_21MM_15-40.jpg';
import bgImg28 from '../../assets/files/PLUGGERS_COVER.JPEG';
import bgImg29 from '../../assets/files/MI_STAINLESS_BURS_COVER.jpg';
import bgImg30 from '../../assets/files/Diamond_Burs_Cover.JPEG';
import bgImg31 from '../../assets/files/CARBIDE_BURS_COVER1.jpg';
import bgImg32 from '../../assets/files/Surgical_Burs_Cover.JPEG';
import bgImg33 from '../../assets/files/MANI_SUTURES_23_NYLON_4-0_SIDE.JPEG';
import bgImg34 from '../../assets/files/GLIDE_FINDERS_COVER.JPEG';
import bgImg35 from '../../assets/files/HARD_STAINLESS_BURS_COVER2.jpg';

export const SubCategories = (props) => {
    const { category } = props;
    const history = useHistory();

    const endonticOptions = [
        { name: 'Reamers', img: bgImg1 },
        { name: 'Medium Reamers', img: bgImg2 },
        { name: 'K Files', img: bgImg3 },
        { name: 'Medium K Files', img: bgImg4 },
        { name: 'H Files', img: bgImg5 },
        { name: 'Medium H Files', img: bgImg6 },
        { name: 'NITI H Files', img: bgImg7 },
        { name: 'Flare Files', img: bgImg8 },
        { name: 'Niti Flare Files', img: bgImg9 },
        { name: 'SEC O K Files', img: bgImg10 },
        { name: 'SEC O H Files', img: bgImg11 },
        { name: 'Flexile Files', img: bgImg12 },
        { name: 'Medium Flexile Files', img: bgImg13 },
        { name: 'RT Files', img: bgImg14 },
        { name: 'Glide Finder', img: bgImg34 },
        { name: 'D Finders', img: bgImg15 },
        { name: 'Barbed Broaches', img: bgImg16 },
        { name: 'Square Broaches', img: bgImg17 },
        { name: 'Short Barbed Broaches', img: bgImg18, }
    ];
    const endodonticRotary = [
        { name: 'Engine Reamers', img: bgImg19 },
        { name: 'Super Files', img: bgImg20 },
        { name: 'U Files', img: bgImg21 },
        { name: 'GPR', img: bgImg22 },
        { name: 'Peeso Reamers', img: bgImg23 },
        { name: 'Gate Drills', img: bgImg24 }];
    const optorationsOptions = [{ name: 'Paste Carriers', img: bgImg25 }, { name: 'Spreaders', img: bgImg26 }, { name: 'Flare Spreaders', img: bgImg27 }, { name: 'Pluggers', img: bgImg28 }];
    const bursOptions = [{ name: 'MI Stainless Burs', img: bgImg29 }, { name: 'Hard Stainless Burs', img: bgImg35 }, { name: 'Diamond Burs', img: bgImg30 }, { name: 'Carbide Burs', img: bgImg31 }, { name: 'Surgical Burs', img: bgImg32 }];
    const suturesOptions = [{ name: 'Sutures', img: bgImg33 }];



    const select = event => {
        if (event.target.dataset.subcategory !== '0') {
            history.push({
                pathname: '/products',
                state: {
                    subcategory: event.target.dataset.subcategory,
                    category
                }
            });
        }
        return;
    }

    switch (category) {
        case '1':
            return (
                <section className='sub-categories-holder'>
                    {endonticOptions.map((item, index) => (
                        <div
                            className="sub-categories-holder-item"
                            key={index}
                            onClick={select}
                            data-subcategory={item.name}
                            style={{ backgroundImage: `url(${item.img})` }}>
                            <div className="front" />
                            <div className="back"><span>{item.name}</span>
                            </div>
                        </div>
                    ))}
                </section>);
        case '2':
            return (
                <section className='sub-categories-holder'>
                    {endodonticRotary.map((item, index) => (
                        <div className="sub-categories-holder-item"
                            key={index}
                            onClick={select}
                            data-subcategory={item.name}
                            style={{ backgroundImage: `url(${item.img})` }}>
                            <div className="front" onClick={select} value={item.name} />
                            <div className="back"><span>{item.name}</span></div>
                        </div>
                    ))}
                </section>);
        case '3':
            return (
                <section className='sub-categories-holder'>
                    {optorationsOptions.map((item, index) => (
                        <div className="sub-categories-holder-item"
                            key={index}
                            onClick={select}
                            data-subcategory={item.name}
                            style={{ backgroundImage: `url(${item.img})` }}>
                            <div className="front" onClick={select} value={item.name} />
                            <div className="back"><span>{item.name}</span></div>
                        </div>
                    ))}
                </section>);
        case '4':
            return (
                <section className='sub-categories-holder'>
                    {bursOptions.map((item, index) => (
                        <div className="sub-categories-holder-item"
                            key={index}
                            onClick={select}
                            data-subcategory={item.name}
                            style={{ backgroundImage: `url(${item.img})` }}>
                            <div className="front" onClick={select} value={item.name} />
                            <div className="back"><span>{item.name}</span></div>
                        </div>
                    ))}
                </section>);
        case '5':
            return (
                <section className='sub-categories-holder'>
                    {suturesOptions.map((item, index) => (
                        <div className="sub-categories-holder-item"
                            key={index}
                            onClick={select}
                            data-subcategory={item.name}
                            style={{ backgroundImage: `url(${item.img})` }}>
                            <div className="front" onClick={select} value={item.name} />
                            <div className="back"><span>{item.name}</span></div>
                        </div>
                    ))}
                </section>);
        case '6':
            history.push({
                pathname: '/products',
                state: {
                    category,
                    subcategory: 'non mani products',
                }
            });

            return null;
        case '7':
            history.push({
                pathname: '/products',
                state: {
                    subcategory: 'ROEKO',
                    category
                }
            })
            return null;
        case '8':
            history.push({
                pathname: '/products',
                state: {
                    subcategory: 'JIZAI',
                    category: 2
                }
            })
            return null;
        case '9':
            history.push({
                pathname: '/products',
                state: {
                    subcategory: 'Korean_Materials',
                    category: 8
                }
            })
            return null;
        default:
            return null;
    }
}
