import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import noImage from '../../assets/images/defaultproductimg.png';
import { importAll } from '../../Services/services';
import ReactPixel from '../../Services/reactPixels';
import { CartItem } from '../../Components/CardItem/CardItem';
import { useHistory } from "react-router-dom";
import * as CardActions from './cartActions';
import * as Selectors from '../../Selectors';
import './styles.scss';

export const Cart = (props) => {
    const [images, setImages] = useState([]);
    const [totalPrice, setTotalPrice] = useState([]);
    const { cartProducts } = useSelector(Selectors.cartData);
    const products = ((cartProducts && Object.values(cartProducts)) || []);
    const dispatch = useDispatch();
    const history = useHistory();
    const { dissabled } = props;

    useEffect(() => {
        const savedProducts = JSON.parse(sessionStorage.getItem('savedProducts'));
        const cartExpiresAt = sessionStorage.getItem('cartExpiresAt');
        const importedImages = importAll(require.context('../../assets/images', true, /\.(png|jpe?g|svg|mp4)$/));
        setImages(importedImages);
        ReactPixel.pageView();

        if(checkIfToClearCart(cartExpiresAt)) {
            sessionStorage.clear();
            dispatch(CardActions.clearProductsFromCart());
        }

        if (savedProducts && Object.keys(savedProducts).length > 0) {
            dispatch(CardActions.loadProductFromStorage(savedProducts))
        }
    }, [dispatch]);

    useEffect(() => {
        const cartExpiresAt = sessionStorage.getItem('cartExpiresAt');
        
        if(checkIfToClearCart(cartExpiresAt)) {
            sessionStorage.clear();
            dispatch(CardActions.clearProductsFromCart());
        } else {
            let price = 0;
            products.forEach(val => price += (val.quantity || 1) * val.Price);
            sessionStorage.setItem('savedProducts', JSON.stringify(cartProducts));
            setTotalPrice(price);
        }

    }, [cartProducts, products, dispatch]);

    const checkIfToClearCart = (cartExpiresAt) => {
        const hours = 5; // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
        const now = new Date().getTime();
        
        if (cartExpiresAt == null) {
            sessionStorage.setItem('cartExpiresAt', now);
            return false;
        }
        
        if((Number(now)-Number(cartExpiresAt)) > hours*60*1000) {
            return true;
        }

        return false;
    }

     const removeItemFromCart = (product) => dispatch(CardActions.removeProductToCart(product));

     const goBack = () => history.push('/categories');

     const goToOrder = () => history.push('/order');

     const onCountChange = (item) => dispatch(CardActions.cardProductChange(item));
     
    if (products.length === 0) {
        return (
            <div className='cart'>
                <h3 className='cart-title'>Нямате още продукти в кошницата</h3>
                <span className='cart-buttons' onClick={goBack}>Добави в кошницата</span>
            </div>
        );
    }

    if (products.length >= 1) {
        return (
            <div className='cart'>
                <h3 className='cart-title'>Избрани продукти в кошницата</h3>
                <div className='items-holder'>
                    {products.map(item => {
                        const image = item.Image && images[item.Image] ? images[item.Image].default : noImage;
                        item.image = image;
                        return <CartItem 
                            key={item.Id}
                            item={item}
                            isDissabled={dissabled}
                            onCountChange={onCountChange} 
                            removeItemFromCart={removeItemFromCart} 
                        />;
                    })}
                    <div className='cart-buttons-holder'>
                        <span>Тотал: {Number.parseFloat(totalPrice).toFixed(2)} лв.</span>
                    </div>

                    {!dissabled && <div className='cart-buttons-holder'>
                        <span className='cart-buttons' onClick={goBack}>Продължи с пазаруването</span>
                        <span className='cart-buttons' onClick={goToOrder}>Завърши поръчката</span>
                    </div>}
                </div>
            </div>
        );
    }

}