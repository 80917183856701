export const SET_ORDER = 'SET_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const SAVE_ORDER = 'SAVE_ORDER';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
export const SAVE_ORDER_FAIL = 'SAVE_ORDER_FAIL';


export const setOrder = (order) => ({ type: SET_ORDER, data: { order } });

export const clearOrder = () => ({ type: CLEAR_ORDER });

export const saveOrder = (data) => ({ type: SAVE_ORDER, data });

export const saveOrderSuccess = (result) => ({ type: SAVE_ORDER_SUCCESS, result });

export const saveOrderFail = (error) => ({ type: SAVE_ORDER_FAIL, error });

