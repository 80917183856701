import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as OrderActions from '../Order/orderActions';
import * as CardActions from '../Cart/cartActions';
import * as Selectors from '../../Selectors';
import './styles.scss';

export const ConfirmScreen = (props) => {
    const { cartProducts } = useSelector(Selectors.cartData);
    const { order } = useSelector(Selectors.orderData);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!order || !cartProducts) {
            return history.push('/categories');
        }
        dispatch(OrderActions.saveOrder({
            products: cartProducts,
            order: order
        }));
        dispatch(CardActions.clearProductsFromCart());
        dispatch(OrderActions.clearOrder());
        setTimeout(() => {
            history.push('/categories');
            sessionStorage.clear();
        }, 5000);
    }, [cartProducts, dispatch, history, order]);

    return (
        <div className='confirm'>
            <h3 className='confirm-title'>Вашaтa поръчка беше направена успешно.</h3>
            <h3 className='confirm-title'>Наш представител ще се свърже с вас за потвърждение на поръчката.</h3>
            <h3 className='confirm-title'>Благодорам Ви, че пазарувахте от нас.</h3>
        </div>
    );
}