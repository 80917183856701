export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const CLEAR_PRODUCTS_FROM_CART = 'CLEAR_PRODUCTS_FROM_CART';
export const LOAD_PRODUCTS_FROM_STORAGE = 'LOAD_PRODUCTS_FROM_STORAGE';
export const CARD_PRODUCT_CHANGE = 'CARD_PRODUCT_CHANGE';

export const addProductToCart = (product) => ({ type: ADD_PRODUCT_TO_CART, data: { product } });
export const removeProductToCart = (product) => ({ type: REMOVE_PRODUCT_FROM_CART, data: { product } });
export const loadProductFromStorage = (products) => ({ type: LOAD_PRODUCTS_FROM_STORAGE, data: { products } });
export const clearProductsFromCart = () => ({ type: CLEAR_PRODUCTS_FROM_CART });
export const cardProductChange = (product) => ({ type: CARD_PRODUCT_CHANGE, data: { product } });