import React from 'react';
import './styles.scss';

export const SectionColumn = (props) => {
    const { children } = props;

    return (
        <div className='column'>
            {children}
        </div>
    )   
}