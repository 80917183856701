import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as PostActions from './postsActions';
import * as Selectors from '../../Selectors';
import MetaTags from 'react-meta-tags';
import ReactPixel from '../../Services/reactPixels';
import { Loader } from '../../Components/AnimatedLoader/AnimatedLoader';
import { PostTile } from '../../Components/PostTile/PostTile';
import './styles.scss';

export const Posts = (props) => {
     const postsData = useSelector(Selectors.postsData);
     const { posts, loading, error } = postsData
     const [expandedPost, setExpandedPost] = useState([]);
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        ReactPixel.pageView();
        dispatch(PostActions.getPosts());
     }, [dispatch]);

     useEffect(() => {
        const expandedPost = new Array(posts.length).fill(false);
        setExpandedPost(expandedPost);
     }, [posts]);

     const expandPost = (postNum) => {
        const expanded = expandedPost.map((item, index) => {
            if (postNum === index) {
                return true;
            }
            return false;
        });
        
        setExpandedPost(expanded);
     };

     if (loading) {   
         return (
             <section className='blogs-holder'>
                 <MetaTags>
                    <title>Фейс блог</title>
                    <meta name="description" content="Фей блог за Мани продукти, тук ще намерите описание на всичките ни продукти за какво и как се използват." />
                    <meta name="keywords" content="Фейс, блог, МАНИ, Япония за България, информация, продукти, използване" />
                    <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                    <meta property="og:title" content="Фейс Уебсайт" />
                    <meta property="og:description" content="Фей блог за Мани продукти, тук ще намерите описание на всичките ни продукти за какво и как се използват." />
                    <meta property="og:url" content="http://faceeu.bg/blog/" />
                    <meta property="og:image" content="/src/assets/images/blog.jpg" />
                    <meta property="og:width" content="350" />
                    <meta property="og:height" content="450" />
                    <meta property="og:type" content="jpg" />                
                    <meta property="og:alt" content="Face home page" />
                </MetaTags>
                <h2 className='blogs-holder-header'>Добре дошли в нашия блог</h2>
                <h3 className='blogs-holder-title'>Тук можете да видите най-последните статии новини за нашите продукти</h3>
                <Loader />
             </section> 
         );
    }

     if (!loading && error) {   
        return (
            <section className='blogs-holder'>
                <MetaTags>
                    <title>Фейс блог</title>
                    <meta name="description" content="Фей блог за Мани продукти, тук ще намерите описание на всичките ни продукти за какво и как се използват." />
                    <meta name="keywords" content="Фейс, блог, МАНИ, Япония за България, информация, продукти, използване" />
                    <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                    <meta property="og:title" content="Фейс Уебсайт" />
                    <meta property="og:description" content="Фей блог за Мани продукти, тук ще намерите описание на всичките ни продукти за какво и как се използват." />
                    <meta property="og:url" content="http://faceeu.bg/blog/" />
                    <meta property="og:image" content="/src/assets/images/blog.jpg" />
                    <meta property="og:width" content="350" />
                    <meta property="og:height" content="450" />
                    <meta property="og:type" content="jpg" />                
                    <meta property="og:alt" content="Face home page" />
                </MetaTags>
                <h2 className='blogs-holder-header'>Добре дошли в нашия блог</h2>
                <h3 className='blogs-holder-title'>Тук можете да видите най-последните статии новини за нашите продукти</h3>
                <span className='blogs-holder-noitems'>Опа... Нещо се обърка неможахме да заредим исканите данни!</span>
            </section> 
        );
     }

     if (!loading && posts.length === 0) {   
        return (
            <section className='blogs-holder'>
                <MetaTags>
                    <title>Фейс блог</title>
                    <meta name="description" content="Фей блог за Мани продукти, тук ще намерите описание на всичките ни продукти за какво и как се използват." />
                    <meta name="keywords" content="Фейс, блог, МАНИ, Япония за България, информация, продукти, използване" />
                    <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                    <meta property="og:title" content="Фейс Уебсайт" />
                    <meta property="og:description" content="Фей блог за Мани продукти, тук ще намерите описание на всичките ни продукти за какво и как се използват." />
                    <meta property="og:url" content="http://faceeu.bg/blog/" />
                    <meta property="og:image" content="/src/assets/images/blog.jpg" />
                    <meta property="og:width" content="350" />
                    <meta property="og:height" content="450" />
                    <meta property="og:type" content="jpg" />                
                    <meta property="og:alt" content="Face home page" />
                </MetaTags>
                <h2 className='blogs-holder-header'>Добре дошли в нашият блог</h2>
                <h3 className='blogs-holder-title'>Тук можете да видите най-последните статии новини за нашите продукти</h3>
                <span className='blogs-holder-noitems'>Няма намерени постове!</span>
            </section> 
        );
     }

     return (
        <section className='blogs-holder'>
            <MetaTags>
                <title>Фейс блог</title>
                <meta name="description" content="Фей блог за Мани продукти, тук ще намерите описание на всичките ни продукти за какво и как се използват." />
                <meta name="keywords" content="Фейс, блог, МАНИ, Япония за България, информация, продукти, използване" />
                <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                <meta property="og:title" content="Фейс Уебсайт" />
                <meta property="og:description" content="Фей блог за Мани продукти, тук ще намерите описание на всичките ни продукти за какво и как се използват." />
                <meta property="og:url" content="http://faceeu.bg/blog/" />
                <meta property="og:image" content="/src/assets/images/blog.jpg" />
                <meta property="og:width" content="350" />
                <meta property="og:height" content="450" />
                <meta property="og:type" content="jpg" />                
                <meta property="og:alt" content="Face home page" />
            </MetaTags>
            <h2 className='blogs-holder-header'>Добре дошли в нашият блог</h2>
            <h3 className='blogs-holder-title'>Тук можете да видите най-последните статии новини за нашите продукти</h3>
            <ul className='blogs'>
                {posts.map((item, index) =>
                    <li className='blogs-item' key={index}>
                        <PostTile post={item} isExpanded={expandedPost[index]} onClickHandler={expandPost} />
                    </li>
                )}
            </ul>
        </section> 
     )
}