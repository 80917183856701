import { call, put, takeLatest } from 'redux-saga/effects';
import * as MailActions from './mailActions';
import { MailService } from './services';

export function* watchMailSaga () {
    yield takeLatest(MailActions.SEND_MAIL_INIT, mailItem);
}

export function* mailItem(action) {
    const { mailData, isOrder } = action.data;
    let result = '';

    try {
      if( isOrder ) {
        result = yield call(MailService.sendOrderMail, mailData);
      } else {
        result = yield call(MailService.sendMail, mailData);
      }
      yield put(MailActions.sendMailSuccess(result));
   } catch (error) {
      yield put(MailActions.sendMailFail(error));
   }
}
