import React from 'react';
import './styles.scss';

export const Section = (props) => {
    const { title, children, isParallax, style} = props;

    return (
        <section className={`section-row ${isParallax ? 'parallax' : ''}`} style={style}>
            {title && <div className='section-title-holder'>
                <h2 className='section-title'>{title}</h2>
            </div>}
            <section className='section-content'>
                {children}
            </section>
        </section>
    )   
}