import React, { useState } from 'react';
import { validateField } from '../../utils/utils';
import './styles.scss';

export const Form = (props) => {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    const [subject, setSubject] = useState('');
    const [subjectError, setSubjectError] = useState('');

    const onChange = (event) => {
        const field = event.target.name;
        const value = event.target.value;

        switch (field) {
            case 'name':
                setName(value);
                setNameError(validateField(value, field));
            break;
            case 'email':
                setEmail(value);
                setEmailError(validateField(value, field));
            break;
            case 'subject':
                setSubject(value);
                setSubjectError(validateField(value, field));
            break;
            case 'message':
                setMessage(value);
                setMessageError(validateField(value, field));
            break;
            default:
            break;
        }
    }

    const checkEverything = () => {
        setNameError(validateField(name, 'name'));
        setEmailError(validateField(email, 'email'));
        setSubjectError(validateField(subject, 'subject'));
        setMessageError(validateField(message, 'message'));

        if (!nameError && !emailError && !subjectError && !messageError) {
            props.sendMail({
                name,
                email,
                message,
                subject
            });
        }
    }

    const error = (emailError ||
        nameError ||
        subjectError || 
        messageError);

    return (
        <form id='contactForm'>
            <input
                className={`${nameError ? 'fieldError' : '' }`} 
                placeholder='Вашето име'
                onChange={onChange}
                value={name}
                name='name'
                id='name' 
                type='text' 
            />
            {nameError && <span className='error'>{nameError}</span>}
            <input
                className={`${emailError ? 'fieldError' : '' }`} 
                placeholder='Вашият емайл'
                onChange={onChange}
                value={email}
                name='email'
                id='email' 
                type='text' 
            />
            {emailError && <span className='error'>{emailError}</span>}
            <input
                className={`${subjectError ? 'fieldError' : '' }`} 
                placeholder='Тема'
                onChange={onChange}
                value={subject}
                name='subject'
                id='subject' 
                type='text' 
            />
            {subjectError && <span className='error'>{subjectError}</span>}
            <textarea 
                className={`${messageError ? 'fieldError' : '' }`} 
                id='text' 
                placeholder='Съобщение'
                onChange={onChange}
                value={message}
                name='message'
                rows='4' 
            />
            {messageError && <span className='error'>{messageError}</span>}
            <span 
                 disabled={error}
                 className={error ? 'sendButton--dissabled': 'sendButton'}
                 onClick={checkEverything}>Изпрати</span>
        </form>
    );
}