import * as OrderActions from './orderActions';

const initialProducts = {
    order: null,
}

export const orderReducer = (state=initialProducts, action) => {
    switch(action.type) {
        case OrderActions.SET_ORDER: {
            return  {
                order: {...state.order, ...action.data.order}
            }
        }
        case OrderActions.CLEAR_ORDER: {
            return  {
                order: null
            }
        }
        default:
            return state
    }
}