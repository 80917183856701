import React, { useState, useEffect } from "react";
import { importAll } from '../../Services/services';
import './styles.scss';

export const PostTile = (props) => {
    const { post, isExpanded, onClickHandler } = props;
    const { id, title, text, image } = post;
    const [images, setImages] = useState([]);

    useEffect(() => {
        const importedImages = importAll(require.context('../../assets/images', false, /\.(png|jpe?g|svg|mp4)$/));
        setImages(importedImages);
     }, []);

    return (
        <section className='post'>
            <div
                className='post-image' 
                style={{backgroundImage: `url(${images[image] && images[image].default}`}}
            >
            </div>
            <div className={`post-post ${isExpanded ? 'expanded': 'collapsed'}`}>
                <h2 className='post-title'>
                    {title}
                </h2>
                <p className='post-text'>
                    {text}
                </p>
                <span
                    onClick={() => onClickHandler(id - 1)}
                    className={`readmore ${isExpanded ? 'hidden': 'visible'}`}>
                    Прочети цялата статия
                </span>
            </div>
        </section>
    )
}