import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export const ProductRate = (props) => {
    const { rate, rateItem } = props;
    return (
        <div className='rating'>
            <FontAwesomeIcon
                onClick={() => rateItem(15)} 
                color={`${rate >= 15 ? '#f0bd16' : '#cdcdcd'}`} 
                className='rate-star' size='1x' icon={faStar} 
            />
            <FontAwesomeIcon
                onClick={() => rateItem(30)} 
                color={`${rate >= 30 ? '#f0bd16' : '#cdcdcd'}`} 
                className='rate-star' size='1x' icon={faStar} 
            />
            <FontAwesomeIcon
                onClick={() => rateItem(45)} 
                color={`${rate >= 45 ? '#f0bd16' : '#cdcdcd'}`} 
                className='rate-star' size='1x' icon={faStar} 
            />
            <FontAwesomeIcon
                onClick={() => rateItem(60)} 
                color={`${rate >= 60 ? '#f0bd16' : '#cdcdcd'}`} 
                className='rate-star' size='1x' icon={faStar} 
            />
            <FontAwesomeIcon
                onClick={() => rateItem(75)} 
                color={`${rate >= 75 ? '#f0bd16' : '#cdcdcd'}`} 
                className='rate-star' size='1x' icon={faStar} 
            />
            <FontAwesomeIcon
                onClick={() => rateItem(100)} 
                color={`${rate >= 100 ? '#f0bd16' : '#cdcdcd'}`}
                className='rate-star' size='1x' icon={faStar} 
            />
         </div>
    )
}