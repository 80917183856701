import axios from 'axios';
const dotenv = require('dotenv');
const parsedValues = dotenv.parse('../../.env');


// const devConfig = {
//   baseURL: 'http://localhost:9000',
//   port: 9000,
//   timeout: 5000,
//   proxy: "http://localhost:3000",
// }

const prodConfig = {
     baseURL: 'http://admin.faceeu.bg',
     timeout: 5000,
}

const devConfig = {
     baseURL: 'http://localhost:9000',
     port: 9000,
     timeout: 5000,
}

let configed = axios.create(prodConfig);

// if (process.env.NODE_ENV === 'production' || parsedValues.ISPRODUCTION === 'true') {
//      configed = axios.create(prodConfig);
// } else {
//      configed = axios.create(devConfig);
// }

console.log(parsedValues, process, configed.defaults.baseURL, process.env);

export const aciosInstance = configed;

