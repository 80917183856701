import { aciosInstance } from '../../network/network';

export const ProductsService = {
    
    getProducts: async (category, skip, take) => {
        return await aciosInstance.get(`/products?skip=${skip || 0}&take=${take || 100}&category=${category || 1}`);
    },

    filterProductsBy: async (filter, category, skip, take) => {
        return await aciosInstance.get(`/filter-products?filter=${filter}&category=${category || 1}&skip=${skip || 0}&take=${take || 100}`);
    },

    rateProduct: async (rate, productId, category) => {
        const body = {
            rate,
            productId,
            category
        }
        const headers = {
            'Content-Type': 'application/json'    
        }
        return await aciosInstance.post('/rate-product', body, headers);
    }
};