import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { validateField, isObjEmpty } from '../../utils/utils';
import { Cart } from '../../Screens/Cart/Cart';
import * as OrderActions from './orderActions';
import ReactPixel from '../../Services/reactPixels';
import { useHistory } from "react-router-dom";
import './styles.scss';

export const Order = (props) => {
    const { location = {} } = props;
    const { state = {} } = location;
    const { isPayment = false } = state;
    const { cartProducts } = useSelector(state => state.cartData);
    const { order } = useSelector(state => state.orderData);
    const [invoice, setInvoice] = useState([false, true]);
    const [payment, setPayment] = useState('Наложен платеж');
    const [orderInfo, setOrderInfo] = useState({});
    const [invoiceInfo, setInvoiceInfo] = useState({});
    const [orderInfoError, setOrderInfoError] = useState({});
    const [invoiceInfoError, setInvoiceInfoError] = useState({});
    const products = (cartProducts && Object.values(cartProducts)) || [];
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (products.length === 0) {
            history.push('/categories')
        }
        setPayment('Наложен платеж')
        ReactPixel.pageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if (order) {
            if (order['factura']) {
                setInvoice([true, false])
            } else {
                setInvoice([false, true])
            }

            setPayment(order['payment'])

            if (order['factura'] && isPayment) {
                setInvoiceInfo({
                    ddsnum: order['ddsnum'],
                    firm: order['firm'],
                    firm_address: order['firm_address'],
                    mol: order['mol'],
                    factura: true
                });
            }
        }
    },[order, isPayment]);
    
    const goBack = () => history.push('/cart');

    
    const goToOrder = () => {

        if (isPayment) {
            history.push('/finalize', { isSuccess: true });
        }

        if (isObjEmpty(orderInfo)) {
            setOrderInfoError({
                email: 'Полето е задължително',
                name: 'Полето е задължително',
                town: 'Полето е задължително',
                address: 'Полето е задължително',
                phone: 'Полето е задължително',
                surname: 'Полето е задължително'
            });
        } else {
            for(let prop in orderInfo) {
                let error = validateField(prop, orderInfo[prop]);
                if (error)
                    setOrderInfoError(Object.assign({}, orderInfoError, {[prop]: error}));
            }
        }

        if (invoice && isObjEmpty(invoiceInfo)) {
            setInvoiceInfoError({
                mol: 'Полето е задължително',
                ddsnum: 'Полето е задължително',
                firm: 'Полето е задължително',
                firm_address: 'Полето е задължително',
            });
        } else {
            for(let prop in invoiceInfo) {
                let error = validateField(prop, invoiceInfo[prop]);
                if (error)
                    setOrderInfoError(Object.assign({}, invoiceInfoError, {[prop]: error}));
            }
        }

        if (
            products.length > 0 && 
            (!isObjEmpty(orderInfo) && isObjEmpty(orderInfoError))) {
                if (invoice && !isObjEmpty(invoiceInfoError)) {
                    return ;
                }
                
                dispatch(OrderActions.setOrder(orderInfo))
                dispatch(OrderActions.setOrder(invoiceInfo))

                return history.push('/payment', { isPayment: true });
        }
    };
console.log(order, orderInfo)
    const makeOrderInfo = (event) => {
        const { name, value } = event.target;
        const error = validateField(value, name);
        setOrderInfoError({...orderInfoError, [name]: error });
        setOrderInfo({...orderInfo, [name]: value });
    };

    const makeInvoiceInfo = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        const error = validateField(value, name);
        setInvoiceInfoError({...invoiceInfoError, [name]: error });
        setInvoiceInfo({...invoiceInfo, [name]: value });
    };
     
    if (products.length === 0) {
        return (
            <div className='order'>
                <h3 className='order-title'>Нямате още избрани продукти в кошницата</h3>
                <span className='order-buttons' onClick={goBack}>Отиди в продукти</span>
            </div>
        );
    }

    if (products.length >= 1) {
        return (
            <div className='order'>
                {!isPayment && <h3 className='order-title'>Вашата поръчка</h3>}
                {isPayment && <h3 className='order-title'>Проформа фактура</h3>}
                <h3 className='order-title'>Избрани продукти</h3>
                <div className='items-holder'>
                    <Cart dissabled />
                </div>
                <div className='form-holder'>
                    <h4 className='order-title'>Данни за Доставка</h4>
                    <form name='form-delivery-info'>
                        <input
                            type='text' 
                            name='name'
                            disabled={isPayment}
                            placeholder='*Име'
                            className={orderInfoError['name'] }
                            value={isPayment ? order['name'] : orderInfo['name'] || ''}
                            onChange={makeOrderInfo}
                        />
                        {orderInfoError['name'] && <span className='error'>{orderInfoError['name']}</span>}
                        <input 
                            type='text' 
                            name='surname'
                            placeholder='*Презиме'
                            disabled={isPayment}
                            className={orderInfoError['surname'] ? 'fieldError': '' }
                            value={isPayment ? order['surname'] : orderInfo['surname'] || ''}
                            onChange={makeOrderInfo}
                        />
                        {orderInfoError['surname'] && <span className='error'>{orderInfoError['surname']}</span>}
                        <input 
                            type='text' 
                            name='email'
                            disabled={isPayment}
                            placeholder='*Емайл'
                            className={orderInfoError['email']? 'fieldError': '' }
                            value={isPayment ? order['email']: orderInfo['email'] || ''}
                            onChange={makeOrderInfo}
                        />
                        {orderInfoError['email'] && <span className='error'>{orderInfoError['email']}</span>}
                        <input 
                            type='text' 
                            name='town'
                            placeholder='*Град'
                            disabled={isPayment}
                            className={orderInfoError['town'] ? 'fieldError': '' }
                            value={isPayment ? order['town'] : orderInfo['town'] || ''}
                            onChange={makeOrderInfo}
                        />
                        {orderInfoError['town'] && <span className='error'>{orderInfoError['town']}</span>}
                        <input 
                            type='text' 
                            name='address'
                            placeholder='*Адрес'
                            disabled={isPayment}
                            className={orderInfoError['address']? 'fieldError': '' }
                            value={isPayment ? order['address'] : orderInfo['address'] || ''}
                            onChange={makeOrderInfo}
                        />
                        {orderInfoError['address'] && <span className='error'>{orderInfoError['address']}</span>}
                        <input 
                            type='text' 
                            name='phone'
                            placeholder='*Телефон'
                            disabled={isPayment}
                            className={orderInfoError['phone'] ? 'fieldError': '' }
                            value={isPayment ? order['phone'] : orderInfo['phone'] || ''}
                            onChange={makeOrderInfo}
                        />
                        {orderInfoError['phone'] && <span className='error'>{orderInfoError['phone']}</span>}
                        <div className='option-holder'>
                            <span>Желаетe ли фактура</span>
                            {isPayment ? invoice[0] ? <span>Да</span> : <span>Не</span> : null}
                            {!isPayment && <div className='radio-holder'>
                                <span>Да</span>
                                <input 
                                    type='radio' 
                                    name='radio' 
                                    value={invoice[0]}
                                    checked={invoice[0]}
                                    disabled={isPayment}
                                    onChange={() =>{ 
                                        setInvoice([true, false]);
                                        dispatch(OrderActions.setOrder({factura: true}));
                                    }}
                                />
                                <span>Не</span>
                                <input 
                                    type='radio' 
                                    name='radio'
                                    value={invoice[1]}
                                    checked={invoice[1]}
                                    disabled={isPayment}
                                    onChange={() => {
                                        setInvoice([false, true]);
                                        dispatch(OrderActions.setOrder({factura: false}));
                                    }}
                                />
                            </div>}
                        </div>
                        <div className='option-holder'>
                            <span>Начин на плащане</span>
                            {isPayment && <span>{payment}</span>}       
                            {!isPayment && <select
                                disabled={isPayment}
                                defaultValue='Наложен платеж'
                                defaultChecked='Наложен платеж'
                                onChange={(event) => {
                                    const { value } = event.target
                                    setPayment(value)
                                    dispatch(OrderActions.setOrder({payment: value}));
                                }}>
                                <option>Наложен платеж</option>
                                <option>Банков път</option>
                            </select>}
                            {!isPayment && <span className='hint'>5% намаление при плащане с карта или по банков път</span>}
                            {payment === 'Начин на плащане' && <span className='error'>Метод на плащане не е избран</span>}
                        </div>
                        {!isPayment && <span className='hint'>* Задължителни полета</span>}
                    </form>
                </div>
                {(isPayment && payment !== 'Наложен платеж') && <div className='form-holder'>
                     <h4 className='order-title'>Данни за плащане на проформа фактура</h4>
                     {payment === 'Банков път' && <div className="payment">
                         <div>
                            <span>SWIFT/BIC:</span>
                            <span>FINVBGSF</span>
                         </div>
                         <div>
                            <span>IBAN:</span>
                            <span>BG91FINV91501017489688</span>
                         </div>
                     </div>}
                </div>}
                {invoice[0] && <div className='form-holder'>
                    <h4 className='order-title'>Данни за фактура</h4>
                    <form name='form-invoice-info'>
                        <input 
                            type='text' 
                            name='ddsnum' 
                            placeholder='*ДДС номер/Бултстат' 
                            className={invoiceInfoError['phone'] ? 'fieldError': ''}
                            disabled={isPayment}
                            value={isPayment ? invoiceInfo['ddsnum'] : invoiceInfo['ddsnum'] || ''}
                            onChange={makeInvoiceInfo}
                        />
                        {invoiceInfoError['ddsnum'] && <span className='error'>{invoiceInfoError['ddsnum']}</span>}
                        <input 
                            type='text' 
                            name='mol' 
                            placeholder='*МОЛ' 
                            className={invoiceInfoError['mol'] ? 'fieldError': ''}
                            disabled={isPayment}
                            value={isPayment ? invoiceInfo['mol'] : invoiceInfo['mol'] || ''}
                            onChange={makeInvoiceInfo}
                        />
                        {invoiceInfoError['mol'] && <span className='error'>{invoiceInfoError['mol']}</span>}
                        <input 
                            type='text' 
                            name='firm_address' 
                            placeholder='*Адрес на регистация на фирмата' 
                            className={invoiceInfoError['firm_address'] ? 'fieldError': ''}
                            disabled={isPayment}
                            value={isPayment ? invoiceInfo['firm_address'] : invoiceInfo['firm_address'] || ''}
                            onChange={makeInvoiceInfo}
                        />
                        {invoiceInfoError['firm_address'] && <span className='error'>{invoiceInfoError['firm_address']}</span>}
                        <input
                            type='text' 
                            name='firm' 
                            placeholder='*Фирма' 
                            className={invoiceInfoError['firm'] ? 'fieldError': ''}
                            disabled={isPayment}
                            value={isPayment ? invoiceInfo['firm'] : invoiceInfo['firm'] || ''}
                            onChange={makeInvoiceInfo}
                        />
                        {invoiceInfoError['firm'] && <span className='error'>{invoiceInfoError['firm']}</span>}
                        {!isPayment && <span className='hint'>* Задължителни полета</span>}
                    </form>
                </div>}
                <div className='order-buttons-holder'>
                    <button className='order-buttons' onClick={goBack}>Върни се обратно</button>
                    <button className='order-buttons' onClick={goToOrder}>Напред</button>
                </div>
            </div>
        );
    }

}