import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import ShoppingCart from '../../assets/images/Shopping Cart.svg'
import logo from "../../assets/images/trlogo.png";
import * as Selectors from '../../Selectors';
import "./styles.scss";

export const Menu = props => {
  const [showVerticalMenu, setShowVerticalMenu] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const { cartProducts } = useSelector(Selectors.cartData);
  const cartItems = cartProducts && Object.values(cartProducts).length;
  const menuContainer = useRef(null);

  const windowResize = useCallback(event => {
    const clientWith = event.currentTarget.innerWidth;
    if (clientWith >= 600 && showVerticalMenu) {
      setShowVerticalMenu(false);
    }
  }, [showVerticalMenu])

  useEffect(() => {
    window.addEventListener("resize", windowResize);
    setMenuHeight(menuContainer.current && menuContainer.current.clientHeight);  
    return () => window.removeEventListener('resize', windowResize)  
  }, [windowResize]);

  return (
    <>
      <section className="menu-holder" ref={menuContainer}>
        <div className="logo-holder">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <ul className="menu">
            <li className="menu-item">
              <Link to="/">За&nbsp;Фейс</Link>
            </li> 
            <li className="menu-item">
              <Link to="/blog">Блог</Link>
            </li>
            <li className="menu-item">
              <Link to="/categories">Продукти</Link>
            </li>
        </ul>
        <div className="cart-menu">
          <Link to="/cart">
            {cartItems > 0 ? <div className="cart-items">
                <span className="cart-items-number">
                    {cartItems}
                </span>
              </div>: null}
              <img src={ShoppingCart} className="shopping-cart" alt="shopping-cart" />
          </Link>
          </div>
        <div className="hamburger-menu" onClick={() => setShowVerticalMenu(!showVerticalMenu)}>
          <div className={`line ${showVerticalMenu ? 'change' : ''}`} />
          <div className={`line ${showVerticalMenu ? 'change' : ''}`} />
          <div className={`line ${showVerticalMenu ? 'change' : ''}`} />
        </div>
      </section>
      <ul className={`menu--vertical ${showVerticalMenu ? 'visible' : ''}`}>
        <li className="menu-item menu-item--lighter"  onClick={() => showVerticalMenu ? setShowVerticalMenu(!showVerticalMenu) : {}}>
          <Link to="/">За&nbsp;Фейс</Link>  
        </li> 
        <li className="menu-item menu-item--lighter"  onClick={() => showVerticalMenu ? setShowVerticalMenu(!showVerticalMenu) : {}}>
          <Link to="/blog">Блог</Link>
        </li>
        <li className="menu-item menu-item--lighter"  onClick={() => showVerticalMenu ? setShowVerticalMenu(!showVerticalMenu) : {}}>
          <Link to="/categories">Продукти</Link> 
        </li>
      </ul>
    </>
  );
};
