import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as ProductsActions from './productActions';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import * as CartActions from '../Cart/cartActions';
import { ProductCard } from '../../Components/ProductCard/ProductCard';
import MetaTags from 'react-meta-tags';
import ReactPixel from '../../Services/reactPixels';
import noImage from '../../assets/images/defaultproductimg.png';
import { Loader } from '../../Components/AnimatedLoader/AnimatedLoader';
import { importAll } from '../../Services/services';
import './styles.scss';
import * as Selectors from '../../Selectors';

export const Products = (props) => {
     const productsData = useSelector(Selectors.productsData);
     const { products, loading, loadMore, error, filters, totalCount } = productsData;

     const [images, setImages] = useState([]);
     const [skip, setSkip] = useState(0);
     const [showTopBtn, setShowTopBtn] = useState(false);
     const history = useHistory();
     const dispatch = useDispatch();
     const observer = useRef(null);
     const list = useRef();
     const { location = {} } = history
     const { state: { category, subcategory } = {} } = location
     
     useEffect(() => {
        const borcheta = importAll(require.context('../../assets/borcheta', true, /\.(jpe?g|svg|mp4)$/i));
        const files = importAll(require.context('../../assets/files', true, /\.(jpe?g|svg|mp4)$/i));
        const others = importAll(require.context('../../assets/others', true, /\.(jpe?g|svg|mp4)$/i));
        
        ReactPixel.pageView();
        setImages({...borcheta, ...files, ...others});
     }, [dispatch, location, products.length]);

     useEffect(() => {
        const parent = document.querySelector('#root')
        if (list.current) {
            parent.addEventListener('scroll', () => {
                const scroll = Math.abs(list.current?.getBoundingClientRect()?.top - list.current?.offsetTop);
                if (scroll > 700) {
                    setShowTopBtn(true);
                } else {
                    setShowTopBtn(false);
                }
            });
        }
    }, [list]);

     const lastElement = useCallback(node => {
        if (loading || loadMore) return;
        
        if (observer.current) observer.current.disconnect()
        
        observer.current = new IntersectionObserver(entries => {
          
            if (skip < totalCount && entries[0].isIntersecting) {

                setSkip(prevSkip => prevSkip + 50);

                dispatch(ProductsActions.loadMore(skip + 50, 50, category, filters, true ));
                
                dispatch(ProductsActions.saveFilters({skip: skip + 50, ...filters }));
        
          }
        }, {threshold: 1});
       
        if (node) observer.current.observe(node)
      }, [loading, loadMore, skip, totalCount, filters, dispatch, category]);


      const sendQuery = useCallback(() => {
        if (subcategory) {
            dispatch(ProductsActions.saveFilters({subcategory, category}));
            return dispatch(ProductsActions.filterProductsBy(subcategory, category));
        }
    }, [category, subcategory, dispatch]);


     useEffect(() => {
        sendQuery()
        setSkip(0);
     }, [sendQuery]);


    const addProduct = (product) => dispatch(CartActions.addProductToCart(product));

    const goToTop = () => {
        const parent = document.querySelector('#root')
        parent.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const goToDetails = (productId) => {
        dispatch(ProductsActions.selectProduct(productId));
        return history.push('/product-details');
    };

    const rateProduct = (rate, productId, category) => dispatch(ProductsActions.rateProduct(rate, productId, category));

    const clearFilters = () => {
        dispatch(ProductsActions.clearFilters());
    };

    if (loading) {   
        return (
            <section className='products-holder'>
                 <MetaTags>
                        <title>Фей онлайн каталог</title>
                        <meta name="description" content="Фей онлайн каталог." />
                        <meta name="keywords" content="Фейс, МАНИ, Япония за България, канални инструменти, борчета, пили, ултразвукови разширители, 
                        RT – пили, карбидни борчета, NRT- пили, машинни пили" />
                        <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                        <meta property="og:title" content="Фейс Уебсайт" />
                        <meta property="og:description" content="Фей онлайн каталог." />
                        <meta property="og:url" content="http://faceeu.bg/blog/" />
                        <meta property="og:image" content="/src/assets/images/blog.jpg" />
                        <meta property="og:width" content="350" />
                        <meta property="og:height" content="450" />
                        <meta property="og:type" content="jpg" />                
                        <meta property="og:alt" content="Face home page" />
                </MetaTags>
                <h2 className='products-holder-header'>Добре дошли в нашия електронен каталог</h2>
               <Loader />
            </section> 
        );
    }
    
    if (!loading && error) {   
        return (
            <section className='products-holder'>
                    <MetaTags>
                        <title>Фей онлайн каталог</title>
                        <meta name="description" content="Фей онлайн каталог." />
                        <meta name="keywords" content="Фейс, МАНИ, Япония за България, канални инструменти, борчета, пили, ултразвукови разширители, 
                        RT – пили, карбидни борчета, NRT- пили, машинни пили" />
                        <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                        <meta property="og:title" content="Фейс Уебсайт" />
                        <meta property="og:description" content="Фей онлайн каталог." />
                        <meta property="og:url" content="http://faceeu.bg/blog/" />
                        <meta property="og:image" content="/src/assets/images/blog.jpg" />
                        <meta property="og:width" content="350" />
                        <meta property="og:height" content="450" />
                        <meta property="og:type" content="jpg" />
                        <meta property="og:alt" content="Face home page" />
                    </MetaTags>
                    <h2 className='products-holder-header'>Добре дошли в нашия електронен каталог</h2>
                    <button id="sendButton" onClick={sendQuery}>Търси</button>
                    <button id="clearButton" onClick={clearFilters}>Изчисти филтрите</button>
                <span className='products-holder-noitems'>Опа... Нещо се обърка неможахме да заредим исканите данни!</span>
            </section> 
        );
    }

    if (!loading && products.length === 0) {   
        return (
            <section className='products-holder'>
                    <MetaTags>
                        <title>Фей онлайн каталог</title>
                        <meta name="description" content="Фей онлайн каталог." />
                        <meta name="keywords" content="Фейс, МАНИ, Япония за България, канални инструменти, борчета, пили, ултразвукови разширители, 
                        RT – пили, карбидни борчета, NRT- пили, машинни пили" />
                        <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                        <meta property="og:title" content="Фейс Уебсайт" />
                        <meta property="og:description" content="Фей онлайн каталог." />
                        <meta property="og:url" content="http://faceeu.bg/blog/" />
                        <meta property="og:image" content="/src/assets/images/blog.jpg" />
                        <meta property="og:width" content="350" />
                        <meta property="og:height" content="450" />
                        <meta property="og:type" content="jpg" />                
                        <meta property="og:alt" content="Face home page" />
                    </MetaTags>
                    <h2 className='products-holder-header'>Добре дошли в нашия електронен каталог</h2>
                    <button id="sendButton" onClick={sendQuery}>Търси</button>
                    <button id="clearButton" onClick={clearFilters}>Изчисти филтрите</button>
                <span className='products-holder-noitems'>Няма намерени продукти!</span>
            </section>
        );
    }

    return (
        <section className='products-holder'>
            <MetaTags>
                <title>Фей онлайн каталог</title>
                <meta name="description" content="Фей онлайн каталог." />
                <meta name="keywords" content="Фейс, МАНИ, Япония за България, канални инструменти, борчета, пили, ултразвукови разширители, 
                RT – пили, карбидни борчета, NRT- пили, машинни пили" />
                <meta name="robots" follow="follow, index, max-snipped: -1, max-video-preview:-1, max-image-preview: large"/>
                <meta property="og:title" content="Фейс Уебсайт" />
                <meta property="og:description" content="Фей онлайн каталог." />
                <meta property="og:url" content="http://faceeu.bg/blog/" />
                <meta property="og:image" content="/src/assets/images/blog.jpg" />
                <meta property="og:width" content="350" />
                <meta property="og:height" content="450" />
                <meta property="og:type" content="jpg" />                
                <meta property="og:alt" content="Face home page" />
            </MetaTags>
            <h2 className='products-holder-header'>Добре дошли в нашия електронен каталог</h2>
            {showTopBtn && (<span onClick={goToTop} id="scroll-to-top">
                <FontAwesomeIcon color='#FFF' size='3x' icon={faArrowAltCircleUp} />
            </span>)}
            <section className="products-content">
                <ul className='content-list' ref={list}>
                    {products.map((item, index) => {
                        const img = (item.Image !== undefined && item.Image !== null) ? item.Image : noImage;
                        const imgs = img.indexOf(',') > -1 ? item.Image.split(',').map(item => item && item.trim()) : [item.Image.trim()];
                        item.images = imgs && imgs.map(item => images[item] !== undefined ? images[item] : null)
                        if (products.length - 1 === index) {
                            return (
                                <li ref={lastElement} key={item.Id}>
                                    <ProductCard 
                                        onClickHandler={goToDetails} 
                                        addProduct={addProduct}
                                        product={item} 
                                        rateProduct={rateProduct} />
                                </li>
                            );
                        }
                        return (
                            <li key={item.Id}>
                                <ProductCard 
                                    onClickHandler={goToDetails} 
                                    addProduct={addProduct}
                                    product={item} 
                                    rateProduct={rateProduct} />
                            </li>
                        );
                    })}
                </ul>
                {loadMore && <div style={{flex: 1, width: '100%'}}><Loader /></div>}
            </section>
        </section> 
     )
}

