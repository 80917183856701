import root from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { runAllSagas } from './sagas/index';
import { composeWithDevTools } from "redux-devtools-extension";
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

const sagaMidleware = createSagaMiddleware();

let middlewares = null;

if (process.env.NODE_ENV !== 'production') {
  middlewares = composeWithDevTools(applyMiddleware(logger, sagaMidleware));
} else {
  middlewares = composeWithDevTools(applyMiddleware(sagaMidleware));
}

export const store = createStore(
    root,
    {},
    middlewares
);

sagaMidleware.run(runAllSagas);