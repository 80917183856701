export const SEND_MAIL_INIT = 'SEND_MAIL_INIT'; 
export const SEND_MAIL_FAIL = 'SEND_MAIL_FAIL'; 
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS'; 

export const sendMail = (mailData, isOrder) => ({
    type: SEND_MAIL_INIT,
    data: {
        mailData,
        isOrder
    }
});

export const sendMailSuccess = (result) => ({
    type: SEND_MAIL_SUCCESS,
    result,
});

export const sendMailFail = (error) => ({
    type: SEND_MAIL_FAIL,
    error,
});