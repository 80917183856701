/* eslint-disable no-fallthrough */
import * as CartActions from './cartActions';
import moment from 'moment';

const initialProducts = {
    cartProducts: {}
}

export const cartReducer = (state=initialProducts, action) => {
    switch (action.type) {
        // eslint-disable-next-line no-lone-blocks
        case CartActions.ADD_PRODUCT_TO_CART: {
            let product = action.data.product;
            let cartProducts = state.cartProducts
            if (!product.quantity) {
                product.quantity = 1;
            }
            sessionStorage.setItem('cartExpiresAt', moment().add(5, 'min'));
            
            if (cartProducts && cartProducts[product.Id]) {
                cartProducts[product.Id].quantity += 1;
                sessionStorage.setItem('savedProducts', JSON.stringify(cartProducts));
                return {
                    cartProducts,
                }
            } else {
                let newProducts = {}
                if (cartProducts) {
                    newProducts = { ...cartProducts, [product.Id]: product };
                } else {
                    newProducts = { [product.Id]: product };
                }
                
                sessionStorage.setItem('savedProducts', JSON.stringify(newProducts));
                return {
                    cartProducts: newProducts
                }
            }
        };
        // eslint-disable-next-line no-lone-blocks
        case CartActions.REMOVE_PRODUCT_FROM_CART: {
            let product = action.data.product;
            if (state.cartProducts[product.Id]) {
                delete state.cartProducts[product.Id]
            }

            return ({
                cartProducts: state.cartProducts
            });
        };
        case CartActions.LOAD_PRODUCTS_FROM_STORAGE: {
            let { products } = action.data;
            return ({
                cartProducts: products
            });
        };
        // eslint-disable-next-line no-lone-blocks
        case CartActions.CLEAR_PRODUCTS_FROM_CART: {
            return ({
                cardProducts: {}
            });
        };
        // eslint-disable-next-line no-lone-blocks
        case CartActions.CARD_PRODUCT_CHANGE: {
            const product = action.data.product;
            const { cartProducts } = state;
            
            if (cartProducts[product.Id] && cartProducts[product.Id].Id === product.Id ) {
                cartProducts[product.Id].quantity = product.quantity;
                sessionStorage.setItem('savedProducts', JSON.stringify(cartProducts));
                return {
                    cartProducts,
                }
            }
        };
        default:
            return state;
    }
}