import { call, put, takeLatest } from 'redux-saga/effects';
import * as PostActions from './postsActions';
import { PostsService } from './services';

export function* watchPostsSaga () {
    yield takeLatest(PostActions.GET_POSTS_INIT, fetchPosts);
}

export function* fetchPosts(action) {
    try {
      const result = yield call(PostsService.getPosts);
      yield put(PostActions.getPostsSuccess(result));
   } catch (error) {
      console.log(error)
      yield put(PostActions.getPostsFail(error));
   }
}