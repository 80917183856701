import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { $ } from 'react-jquery-plugin';
import { ProductRate } from '../ProductRate/ProductRate';
import Img from 'react-cool-img';
import loadingImg from '../../assets/images/loading.gif';
import noImage from '../../assets/images/defaultproductimg.png';
import './styles.scss';

export const ProductCard = (props) => {
    const { product, rateProduct, onClickHandler, addProduct } = props;
    const [rate, setRate] = useState(0);
    const [filtered, setFiltered] = useState([]);


    useEffect(() => {
        if (product.Rate) {
            setRate(product.Rate);
        }
        if(product.images){
            setFiltered(product.images.filter(item => item !== undefined))
        }
    }, [product]);


    const rateItem = (rate) => {
        setRate(rate)
        rateProduct(rate, product.Id, product.Category);
    }

    const addProductToCart = (event, product) => {
        addProduct(product)
        const parent = $(event.currentTarget.parentNode).parent()
        parent.find(".card-overlay").css({
            'transform': ' translateY(0px) translateZ(150px)',
            'opacity': '1',
            'transition': 'all ease-in-out .45s'
          }).delay(1500).queue(function() {
            $(this).css({
              'transform': 'translateY(-800px) translateZ(-150px)',
              'opacity': '0',
              'transition': 'all ease-in-out .45s'
            }).dequeue();
          });
    }

    return (
        <div className="card">
            {product.In_Promo ? (<div className="promo">
                <span className="promo-content">Промоция</span>
            </div>) : null}
            {product.Is_New ? (<div className="promo">
                <span className="promo-content promo-content--new">Ново</span>
            </div>) : null}
            <div className="card-inner">
                <h3>{product.Name}</h3>
                <Img 
                    placeholder={loadingImg}
                    onClick={() => onClickHandler(product.Id)}
                    src={filtered[0] ? filtered[0].default : noImage}
                    error={noImage}
                    alt={product.Name + ' image'}
                />
                <ProductRate rate={rate} rateItem={rateItem} />
                <h4>Размер: {product.Size}</h4>
                <h4>Работна дължина: {product.Working_Length}</h4>
                <h4>Цена {Number.parseFloat(product.Price).toFixed(2)}&nbsp;лв.</h4>
                <div className="details-short-color" style={{backgroundColor: product.Catalog_Color && product.Catalog_Color !== 'ASORTI' ? product.Catalog_Color: '#FFFFFF' }}></div>
                <div className="button" onClick={(event) => addProductToCart(event, product)}>
                    <FontAwesomeIcon color='#313131' size='1x' icon={faCartPlus} />
                </div>
            </div>
            <div className="card-overlay">
                <h3>Добавено в кошницата</h3>
                <FontAwesomeIcon color='#3C9015' size='2x' icon={faCheckCircle} />
            </div>
        </div>
    );
}