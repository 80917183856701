import React, { useRef, useEffect } from "react"
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import "./styles.scss";
import "ol/ol.css";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import trlogo from '../../assets/images/trlogo.png';


export const MapWrapper = (props) => {
  const mapRef = useRef(null);
  
  useEffect(() => {
    let marker = new Feature({
      geometry: new Point([2596161.4939065184, 5264431.581948938]),  // Cordinates of New York's Town Hall
    });

    marker.setStyle(
      new Style({
        image: new Icon({
          crossOrigin: 'anonimous',
          src: trlogo
        })
      })
    )
    
    let vectorSource = new VectorSource({
      features: [marker]
    });
    
    let markerVectorLayer = new VectorLayer({
      source: vectorSource,
    });

     let map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          })
        }),
      ],
      view: new View({
        center: [2596139.0653553926, 5264400.386786507],
        zoom: 19
      })
    });

    map.addLayer(markerVectorLayer);
  }, []);

  return (
    <div id="map" ref={mapRef} className="map" ></div>
  )
}


