export const GET_PRODUCTS_INIT = 'GET_PRODUCTS_INIT';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const LOAD_MORE_INIT = 'LOAD_MORE_INIT';
export const LOAD_MORE_SUCCESS = 'LOAD_MORE_SUCCESS';
export const LOAD_MORE_FAIL = 'LOAD_MORE_FAIL';
export const RATE_PRODUCT_INIT = 'RATE_PRODUCT_INIT';
export const RATE_PRODUCT_FAIL = 'RATE_PRODUCT_FAIL';
export const RATE_PRODUCT_SUCCESS = 'RATE_PRODUCT_SUCCESS';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const FILTER_PRODUCTS_SUCCEESS = 'FILTER_PRODUCTS_SUCCESS';
export const FILTER_PRODUCTS_FAIL = 'FILTER_PRODUCTS_FAIL';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const DESELECT_PRODUCT = 'DESELECT_PRODUCT';
export const SAVE_FILTERS = 'SAVE_FILTERS';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const CLEAR_FILTERS = 'CLEAR_FILTER';

export const getProducts = (skip, take, category, refresh) => ({ 
    type: GET_PRODUCTS_INIT, 
    data: {
        skip,
        take, 
        category,
        refresh
    }});
    
export const loadMore = (skip, take, category, filterBy, refresh) => ({ 
    type: LOAD_MORE_INIT, 
    data: {
        skip,
        take, 
        category,
        filterBy,
        refresh
    }});

export const loadMoreSuccess = (result) => ({ type: LOAD_MORE_SUCCESS, result });

export const loadMoreFail = (error) => ({ type: LOAD_MORE_FAIL, error });

export const getProductsSuccess = (result) => ({ type: GET_PRODUCTS_SUCCESS, result });

export const getProductsFail = (error) => ({ type: GET_PRODUCTS_FAIL, error });

export const filterProductsBy = (filterBy, category) => ({ 
    type: FILTER_PRODUCTS, 
    data: {
        filterBy, 
        category
    } 
});

export const filterProductsSuccess = (result) => ({ 
    type: FILTER_PRODUCTS_SUCCEESS, 
    result
});

export const filterProductsFail = (error) => ({ 
    type: FILTER_PRODUCTS_FAIL, 
    error
});

export const rateProduct = (rate, productId, category) => ({ 
    type: RATE_PRODUCT_INIT,
    data: {
        rate, 
        productId, 
        category
} });
export const rateProductFail = (error) => ({ type: RATE_PRODUCT_FAIL, error });
export const rateProductSuccess = (result) => ({ type: RATE_PRODUCT_SUCCESS, result });
export const selectProduct = (id) => ({ type: SELECT_PRODUCT, id });
export const deselectProduct = (id) => ({ type: DESELECT_PRODUCT });
export const saveFilters = (filters) => ({ type: SAVE_FILTERS, filters });
export const clearFilters = () => ({ type: CLEAR_FILTERS });




